//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Preservation from '@/components/preservation';
export default {
  components: {
    Preservation,
  },
  data() {
    return {
      effectiveTimeVal: '',
      receiveTime: '',
      userGrade: [],
      form: {
        name: '', //名称
        type: 1, //优惠券类型，1满减券，2立减券，3满折券
        consume_full: '', //消费满
        subtract: '', //立减
        discount: '', //	折扣
        activity_start_time: '', //活动开始时间
        activity_end_time: '', //活动结束时间
        time_type: 1, //有效期类型，1固定时间，2领取后N天内
        start_time: '', //有效开始时间
        end_time: '', //	有效结束时间
        days: '', //有效天数
        level_id: 0, //会员级别ID
        number: '', //发放数量
        each_limit: '', //每人限领
        status: 1, //状态，0停用，1正常
      },
      rules: {
        name: [{ required: true, message: '请填写分类名称', trigger: 'blur' }],
        activity_start_time: [{ required: true, message: '请填写领券时间', trigger: 'blur' }],
        start_time: [{ required: true, message: '请填写优惠券有效期', trigger: 'blur' }],
        time_type: [{ required: true, message: '请填写优惠券有效期', trigger: 'blur' }],
        days: [{ required: true, message: '请填写优惠券有效期', trigger: 'blur' }],
        number: [{ required: true, message: '请填写发放数量', trigger: 'blur' }],
        each_limit: [{ required: true, message: '请填写限领数量', trigger: 'blur' }],
        type: [{ required: true, message: '', trigger: 'blur' }],
      },
    };
  },
  created() {
    let couponInfo = this.$route.query.info;
    if (couponInfo) {
      let utils = this.$store.state.utils;
      utils.page = Number(this.$route.query.page);
      utils.rows = Number(this.$route.query.rows);
      utils.is_record = !0;
      couponInfo = JSON.parse(couponInfo);
      this.couponInfo(couponInfo);
    }
  },
  methods: {
    //处理编辑信息
    couponInfo(couponInfo) {
      this.effectiveTimeVal = [new Date(couponInfo.start_time * 1000), new Date(couponInfo.end_time * 1000)];
      this.receiveTime = [new Date(couponInfo.activity_start_time * 1000), new Date(couponInfo.activity_end_time * 1000)];
      this.form = couponInfo;
    },
    getEffectiveTimeVal(val) {
      this.form.start_time = val[0].getTime() / 1000;
      this.form.end_time = val[1].getTime() / 1000;
    },
    getReceiveTime(val) {
      this.form.activity_start_time = val[0].getTime() / 1000;
      this.form.activity_end_time = val[1].getTime() / 1000;
    },
    submit() {
      try {
        let form = this.form;
        if (!form.name) throw '请填写优惠券名称';
        if (form.type == 1) {
          if (!form.consume_full) throw '请填写消费满多少';
          if (!form.subtract) throw '请填写立减多少';
        } else if (form.type == 2) {
          if (!form.subtract) throw '请填写立减多少';
        } else {
          if (!form.consume_full) throw '请填写消费满多少';
          if (!form.discount) throw '请填写折扣多少';
        }
        if (!this.receiveTime) throw '请填写领券时间';
        if (form.time_type == 1) {
          if (!this.effectiveTimeVal) throw '请填写优惠券有效期';
        } else {
          if (!form.days) throw '请填写优惠券有效期';
        }
        if (!form.number) throw '请填写发放数量';
        if (!form.each_limit) throw '请填写限领数量';
        let url = form.id ? this.$api.push.couponEdit : this.$api.push.addCoupon;
        this.$axios.post(url, form).then(res => {
          if (res.code == 0) {
            this.$message({
              message: form.id ? '编辑成功' : '添加成功',
              type: 'success',
            });
            this.$router.push('/extension/couponList');
          } else {
            this.$message.error(res.msg);
          }
        });
      } catch (e) {
        this.$message({
          message: e,
          type: 'warning',
        });
      }
    },
  },
};
